import React, { useState } from "react";
import { connect } from "react-redux";
import { Text } from "../../../containers/Language";
import { LanguageProvider } from "../../../containers/Language";
import useWindowDimension from "../../../hooks/useWindowDimension";
import { showToast } from "../../../state/ducks/utils/operations";
import HookForm from "../../../components/HookForm/HookForm";
import CheckBox from "../../../components/UI/CheckBox/CheckBox";
import { login, initializeSession } from "../../../state/ducks/auth/operations";
import Scrollbars from "react-custom-scrollbars";
import Constant from "../../../util/constant";
import { get } from "lodash";
import StorageService from "../../../services/localstorage.service";
import "./Login.scss";
import MetaTags from "react-meta-tags";

import { Link, withRouter } from "react-router-dom";
import AuthHeader from "../../../layout/AuthHeader/AuthHeader";

import TextField from "../../../components/UI/AuthTextField/AuthTextField.js";

const loginForm = {
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
        message: "EMAIL_ERROR_VALIDATE",
      },
      pattern: {
        value: Constant.REGEX.EMAIL,
        message: "EMAIL_VALID",
      },
    },
  },
  password: {
    name: "password",
    validate: {
      required: {
        value: true,
        message: "PASSWORD_EMPTY",
      },
    },
  },
};

function Login(props) {
  const dimensions = useWindowDimension();

  const [form, setLoginForm] = useState();
  const [showPass, setShowPass] = useState(false);
  const [rememberMe, setRememberMe] = useState(
    StorageService.getItem("ubikate_remember_me") || false
  );
  const [busy, setBusy] = useState(false);

  const onKeyDown = (e) => {
    // e.keyCode === 13 && onFormSubmit();
  };

  const onFormSubmit = async (data) => {
    try {
      let param = {
        email: data.email,
        password: data.password,
        role: 2,
      };
      setBusy(true);
      let response = await props.login(param);
      const { message, payload } = response;
      const { user, tokens } = payload;
      setBusy(true);
      // console.log("payload : ", user);
      if (user.registerComplete) {
        props.initializeSession({ user, tokens });
        if (rememberMe) {
          StorageService.setItem("ubikate_email", data.email);
          StorageService.setItem("ubikate_password", data.password);
        } else {
          StorageService.removeItem("ubikate_email");
          StorageService.removeItem("ubikate_password");
        }
        StorageService.setItem("ubikate_remember_me", rememberMe);

        let lan = StorageService.getItem("rcml-lang");
        if (lan === "es") {
          props.showToast({
            message: "Conectado con éxito.",
            type: "success",
          });
        } else if (lan === "pt") {
          props.showToast({
            message: "Conectado com sucesso.",
            type: "success",
          });
        } else {
          props.showToast({
            message: "Logged in successfully.",
            type: "success",
          });
        }
        setTimeout(() => {
          // setBusy(false)
          props.history.push("/dashboard");
        }, 1000);
      } else {
        setBusy(false);

        let visibleview;
        console.log(user.registerStep + 1);
        switch (user.registerStep + 1) {
          case 2:
            visibleview = "SixStep";
            break;
          case 4:
            visibleview = "ThirdStep";
            break;
          case 5:
            visibleview = "FourStep";
            break;
          // case 5:
          //   visibleview = "FourStep";
          // break;
          // case 7:
          //   visibleview = "SevenStep";
          //   break;
          case 6:
            visibleview = "EightStep";
            break;
          case 9:
            visibleview = "NineStep";
            break;
          default:
            visibleview = "SecondStep";
        }
        props.history.push({
          pathname: "/signup",
          state: {
            step: user.registerStep,
            visibleview: visibleview,
            userId: user.id,
          },
        });
      }
    } catch (err) {
      setBusy(false);
      console.log(err.response.data.message);
      let lan = StorageService.getItem("rcml-lang");
      if (err.response.data.message === "VERIFY") {
        localStorage.setItem("email", JSON.stringify({ email: data.email }));
        if (lan === "es") {
          props.showToast({
            message:
              "Necesita verificar su correo electrónico. Verifique su bandeja de entrada de correo electrónico registrado o correo no deseado para la activación de la cuenta.",
            type: "error",
            timer: 30000,
            isButton: true,
          });
        } else if (lan === "pt") {
          props.showToast({
            message:
              "Você precisa verificar seu e-mail. Por favor, verifique a sua caixa de entrada de e-mail registrado ou spam para ativação da conta.",
            type: "error",
            timer: 30000,
            isButton: true,
          });
        } else {
          props.showToast({
            message:
              "You need to verify your email. Please check your registered email inbox or spam for account activation.",
            type: "error",
            timer: 30000,
            isButton: true,
          });
        }
      } else if (err.response.data.message === "DELETED") {
        if (lan === "es") {
          props.showToast({
            message: "Esta cuenta ha sido eliminada y ya no está activa.",
            type: "error",
          });
        } else if (lan === "pt") {
          props.showToast({
            message: "Esta conta foi excluída e não está mais ativa.",
            type: "error",
          });
        } else {
          props.showToast({
            message: "This account has been deleted and is no longer active.",
            type: "error",
          });
        }
      } else if (err.response.data.message === "DISABLED") {
        if (lan === "es") {
          props.showToast({
            message:
              "Esta cuenta está inhabilitada. Comuníquese con el propietario o administrador de su cuenta",
            type: "error",
          });
        } else if (lan === "pt") {
          props.showToast({
            message:
              "Esta conta está desativada. Entre em contato com o proprietário ou administrador da sua conta",
            type: "error",
          });
        } else {
          props.showToast({
            message:
              "This account is disabled. Please contact your account owner or administrator",
            type: "error",
          });
        }
      } else if (err.response.data.message === "NOT_FOUND_EMAIL") {
        if (lan === "es") {
          props.showToast({
            message:
              "¡Lo siento! No pudimos encontrar una cuenta con esa dirección de correo electrónico.",
            type: "error",
          });
        } else if (lan === "pt") {
          props.showToast({
            message:
              "Desculpe! Não conseguimos encontrar uma conta com esse endereço de e-mail",
            type: "error",
          });
        } else {
          props.showToast({
            message:
              "Sorry!  We couldn't find an account with that email address",
            type: "error",
          });
        }
      } else if (err.response.data.message === "INCORRECT_PASSWORD") {
        if (lan === "es") {
          props.showToast({
            message:
              "Contraseña incorrecta.Vuelva a intentarlo o haga clic en 'Olvidé mi contraseña' a continuación.",
            type: "error",
          });
        } else if (lan === "pt") {
          props.showToast({
            message:
              "Senha incorreta. Por favor, tente novamente ou clique em 'Esqueci minha senha' abaixo.",
            type: "error",
          });
        } else {
          props.showToast({
            message:
              "Incorrect password. Please try again or click on 'Forgot Password' below.",
            type: "error",
          });
        }
      } else {
        props.showToast({
          message: get(err, "response.data.message", "somthing want wrong!!!"),
          type: "error",
        });
      }
    }
  };
  const onClickSignUp = () => {
    StorageService.clear();
    props.history.push("/");
  };
  return (
    <LanguageProvider>
      <MetaTags>
        <title>Log In To Your SOMOS Account</title>
        <meta
          name="description"
          content="Log in to SOMOS to meet and connect with Latin Americans, expats & travellers in your local area."
        />
      </MetaTags>
      <AuthHeader />

      <Scrollbars
        className="landingPage"
        style={{ height: dimensions.height + "px" }}
      >
        <div className="main_container flex">
          <video
            autoPlay
            muted
            loop
            src={
              "https://res.cloudinary.com/dllrhzmuu/video/upload/v1722732420/somoslations%20assets/videos/homepage-video_seqyjt.mp4"
            }
            style={{maxWidth:'40vw'}}
            className="left"
          />
          <img
            className="bottom_mobile_border"
            src="https://res.cloudinary.com/dllrhzmuu/image/upload/v1721436778/somoslations%20assets/email/Mail-Design-colors_qk5oni.jpg"
            alt="bottom-border"
          />
          <img
            className="top_mobile_border"
            src="https://res.cloudinary.com/dllrhzmuu/image/upload/v1721436768/somoslations%20assets/email/Mail-Design-colors2_jokbod.jpg"
            alt="top-border"
          />
          <div className="right">
            <div className="top_container">
              <div className="top_elements">
                <div className="bg_element top_image">
                  <div className="element_wrapper">
                    <img
                      src={
                        "https://res.cloudinary.com/dllrhzmuu/image/upload/v1722732350/somoslations%20assets/homepage/Photo-1_vhqdua.png"
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="bg_element center_image">
                  <div className="element_wrapper">
                    <img
                      src={
                        "https://res.cloudinary.com/dllrhzmuu/image/upload/v1722732348/somoslations%20assets/homepage/Photo-2_iwwhdd.png"
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="bg_element circle1">
                  <div className="element_wrapper"></div>
                </div>
              </div>
              <div className="form_container">
                <div className="hero_text">
                  <img
                    src="https://res.cloudinary.com/dllrhzmuu/image/upload/v1723198225/somoslations%20assets/homepage/Somos-1-Hrzntl-high_qiko7y.png"
                    alt=""
                  />
                  <h3>
                    <strong>
                      <Text tid="SIGNUP_FIND" />
                    </strong>{" "}
                    <strong>
                      <Text tid="SIGNUP_MEET" />
                    </strong>{" "}
                    <Text tid="SIGNUP_AND" />{" "}
                    <strong>
                      <Text tid="SIGNUP_CREATE" />{" "}
                    </strong>
                  </h3>
                  <p>
                    <Text tid="SIGNUP_CONNECT_WITH" />{" "}
                  </p>
                </div>

                <div className="form_elements">
                  <HookForm
                    defaultForm={{
                      email: StorageService.getItem("ubikate_email"),
                      password: StorageService.getItem("ubikate_password"),
                    }}
                    init={(form) => {
                      setLoginForm(form);
                      StorageService.getItem("ubikate_email") && form.trigger();
                    }}
                    onSubmit={onFormSubmit}
                    className="w-full"
                  >
                    {(formMethod) => {
                      return (
                        <>
                          <div className="form_input_container">
                            <TextField
                              formMethod={formMethod}
                              rules={loginForm.email.validate}
                              name={loginForm.email.name}
                              errors={formMethod?.errors}
                              autoFocus={true}
                              type="text"
                              iconClass="icon-at"
                              placeholder="EMAIL_PLACEHOLDER"
                            />
                            <TextField
                              formMethod={formMethod}
                              rules={loginForm.password.validate}
                              name={loginForm.password.name}
                              errors={formMethod?.errors}
                              type={showPass ? "text" : "password"}
                              placeholder="PASSWORD_PLACEHOLDER"
                              iconClass={
                                showPass ? "icon-eye-on" : "icon-eye-off"
                              }
                              onKeyDown={onKeyDown}
                              onIconClick={() => setShowPass(!showPass)}
                            />
                          </div>
                          <div className="actions_container">
                            <div className="sub_actions">
                              <Link to="/forgotpassword">
                                <Text tid="FORGOT_PASSWORD" />
                              </Link>
                              <CheckBox
                                id="remember"
                                label_title="LABEL_TITLE"
                                checked={rememberMe}
                                onCheckedChange={(checked) =>
                                  setRememberMe(checked)
                                }
                              />
                            </div>
                            <button
                              className="register_btn"
                              type="submit"
                              disabled={!formMethod?.formState.isValid}
                              // loading={busy}
                            >
                              <Text tid="LANDING_LOG_IN" />
                            </button>
                          </div>
                        </>
                      );
                    }}
                  </HookForm>

                  <div className="text_divider">
                    <div></div>
                    <p>
                      {" "}
                      <Text tid="OR" />
                    </p>
                    <div></div>
                  </div>

                  <div className="form_title_container">
                    <img src={"https://res.cloudinary.com/dllrhzmuu/image/upload/v1723198225/somoslations%20assets/homepage/Somos-1-globo-high_sjzzkg.png"} alt="" />
                    <div className="title_heading">
                      <p>
                        <Text tid="SIGNUP_JOIN" />
                      </p>
                      <p>
                        <strong>
                          <Text tid="SIGNUP_CONNECT" />
                        </strong>
                      </p>
                    </div>
                  </div>
                  <button className="login_btn" onClick={onClickSignUp}>
                    <Text tid="LANDING_SIGN_UP" />
                  </button>
                </div>
              </div>
            </div>

            <div className="social_links_container">
              <div className="social_links">
                <a
                  href="https://www.facebook.com/todossomoslatinos"
                  target="_blank"
                >
                  <img
                    src={
                      "https://res.cloudinary.com/dllrhzmuu/image/upload/v1722732346/somoslations%20assets/homepage/FB-icon_d9vxeb.png"
                    }
                    alt="fb"
                  />
                </a>
                <a
                  href="https://www.instagram.com/somos.latinxs/"
                  target="_blanlk"
                >
                  <img
                    src={
                      "https://res.cloudinary.com/dllrhzmuu/image/upload/v1722732347/somoslations%20assets/homepage/IG-icon_zkas5k.png"
                    }
                    alt="instagram"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/somoslatinos/"
                  target="_blank"
                >
                  <img
                    src={
                      "https://res.cloudinary.com/dllrhzmuu/image/upload/v1722732347/somoslations%20assets/homepage/LinkedIn-icon_wuocbu.png"
                    }
                    alt="linkedin"
                  />
                </a>
              </div>
              <Text tid="SOCIAL_FOLLOW_US" />
            </div>

            <div className="lower_elements"></div>
          </div>
        </div>
      </Scrollbars>
    </LanguageProvider>
  );
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  showToast,
  login,
  initializeSession,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
